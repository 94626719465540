import React from 'react';

import Layout from '../components/Layout';
import Img from 'gatsby-image'
import Scroll from '../components/Scroll';
import { ArenguForm } from "gatsby-plugin-arengu-forms";
import GermanyIcon from '../assets/logos/germany-1.svg';
import ReportingIcon from '../assets/logos/reporting.svg';
import PersonalIcon from '../assets/logos/personal.svg';
import { graphql } from "gatsby";
import SEO from "../components/Seo"
import mypdf from '../assets/Pdf/CMS-Präs.pdf';

const IndexPage = ({ data }) => (
  <Layout>
   <SEO 
      title={data.contentYaml.title}
      description={data.contentYaml.description}
      pathname=""
      keywords={data.contentYaml.keywords}
    />
    <section id="banner">
      <div className="inner">
        <h2>{data.contentYaml.headline}</h2>
        <p>{data.contentYaml.description}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
              {data.contentYaml.buttonHero}
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
        Mehr erfahren
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
          {data.contentYaml.uspHeader}
          </h2>
          <p>
           {data.contentYaml.uspDescription}
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon major style1">
              <GermanyIcon style={{width: '2em', transform: 'rotate(45deg)'}}/>
            </span><br/><br/>
            <p>Deutschland weit</p>
          </li>
          <li>
            <span className="icon major style1">
              <ReportingIcon style={{width: '2em', transform: 'rotate(45deg)'}}/>
            </span><br/><br/>
            <p>Reporting</p>
          </li>
          <li>
            <span className="icon major style1">
             <PersonalIcon style={{width: '2em', transform: 'rotate(45deg)'}}/>
            </span><br/><br/>
            <p>Individuelle Angebote</p>
          </li>
        </ul>
      </div>
    </section>
    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
        <Img alt={data.imageTwo.name} key={data.imageTwo.childImageSharp.fluid.src} fluid={data.imageTwo.childImageSharp.fluid} />
        </div>
        <div className="content">
          <h2>
          {data.contentYaml.servicesTitleOne}
          </h2>
          <p>
          {data.contentYaml.servicesDescriptionOne}
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <Img alt={data.imageOne.name} key={data.imageOne.childImageSharp.fluid.src} fluid={data.imageOne.childImageSharp.fluid} />
        </div>
        <div className="content">
          <h2>
          {data.contentYaml.servicesTitleTwo}
          </h2>
          <p>
          {data.contentYaml.servicesDescriptionTwo}
          </p>
        </div>
      </section>
      <section className="Unsere">
        <div className="Unsere-headline">
          <h1>Unsere Dienstleistungen</h1>
        </div>
        <div className="h2">
        <h2>Verteilung von:</h2>
        </div>
        <div className="servis">
        <div className="one-ul">
         <ul>
           <li>Handzetteln</li>
           <li>Prospekten</li>
           <li>Warenproben</li>
           <li>Hauswerfsendungen</li>
         </ul>
        </div>
        <div className="two-ul"> 
         <ul>
           <li>Zeitungen</li>
           <li>Zeitschriften</li>
           <li>Katalogen</li>
           <li>Kunden-und Probezeitschriften</li>
         </ul>
        </div>
        </div>
      </section>
    </section>
    <section className="Pdf-file">
      <div className="file">
        <h2>Laden Sie sich jetzt unser Whitepaper runter</h2>
      </div>
      <div className="button-download">
        <button className="button-pdf">
        <a href={mypdf} download="My_File.pdf"> Download Here </a>
        </button>
      </div>
    </section>
    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>{data.contentYaml.referencTitle}</h2>
          <p>
          {data.contentYaml.referencDescription}
          </p>
        </header>
        <div className="features">
          <div className="icons">
          <Img alt={data.BlueEurope.name} key={data.BlueEurope.childImageSharp.fluid.src} fluid={data.BlueEurope.childImageSharp.fluid} />
          </div>
          <div className="icons ">
          <Img alt={data.cityCar.name} key={data.cityCar.childImageSharp.fluid.src} fluid={data.cityCar.childImageSharp.fluid} />
          </div>
          <div className="icons">
          <Img alt={data.extratipp.name} key={data.extratipp.childImageSharp.fluid.src} fluid={data.extratipp.childImageSharp.fluid} />
          </div>
          <div className="icons ">
          <Img alt={data.op.name} key={data.op.childImageSharp.fluid.src} fluid={data.op.childImageSharp.fluid} />
          </div>
          <div className="icons">
          <Img alt={data.sofra.name} key={data.sofra.childImageSharp.fluid.src} fluid={data.sofra.childImageSharp.fluid} />
          </div>
          <div className="icons">
          <Img alt={data.tastypizza.name} key={data.tastypizza.childImageSharp.fluid.src} fluid={data.tastypizza.childImageSharp.fluid} />
          </div>
          <div className="icons">
          <Img alt={data.worldlogistik.name} key={data.worldlogistik.childImageSharp.fluid.src} fluid={data.worldlogistik.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
     {/*Contact form*/}
     <div className="download-file">
       </div>
     <div className="headline-contact-from">
       <h1>Bei Interesse und weiteren Fragen stehen wir Ihnen gerne zur Verfügung</h1>
       </div>
     <div id="main" className="contact-form">
       <ArenguForm
          id="160616362854181051"/>
      </div>
    </section>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    contentYaml(sitename: {eq: "home"}) {
      title
      headline
      description
      buttonHero
      uspHeader
      uspDescription
      servicesTitleOne
      servicesDescriptionOne
      servicesTitleTwo
      servicesDescriptionTwo
      referencTitle
      referencDescription
    }
    imageOne: file(name: {eq: "img1"}) {
      name
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    imageTwo: file(name: {eq: "img2"}) {
      name
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    cityCar: file(name: {eq: "Citycar"}) {
      name
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    BlueEurope: file(name: {eq: "Blue-Europe"}) {
      name
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    extratipp: file(name: {eq: "extratipp"}) {
      name
        childImageSharp {
          fluid(quality: 95,maxWidth: 1200 ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    op: file(name: {eq: "op"}) {
      name
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    sofra: file(name: {eq: "Sofra-kebap-haus"}) {
      name
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    tastypizza: file(name: {eq: "TastyPizza"}) {
      name
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    worldlogistik: file(name: {eq: "World_Logistik_01_preview-1"}) {
      name
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
  }
`